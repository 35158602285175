import React from "react";
import Header from "./Header.js";
import Home from "./Home/Home.js"
import Footer from "./footer.js";
import Contact from "./Contact.js"




export default function App(){
    return(
        <>
            <Header />
            <Home />
            <Footer />
        </>
    )
}