import React, { useEffect, useState } from "react";
import "./ImageGalleryDots.css"; // For styles

const DashedLines = ({ totalDashes, currentDashIndex }) => {
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  useEffect(() => {
    // Update the highlighted dash based on the provided currentDashIndex prop
    if (currentDashIndex >= 0 && currentDashIndex < totalDashes) {
      setHighlightedIndex(currentDashIndex);
    }
  }, [currentDashIndex, totalDashes]);

  const renderDashedLines = () => {
    const lines = [];
    for (let i = 0; i < totalDashes; i++) {
      lines.push(
        <span
          key={i}
          className={`dashed-line ${i === highlightedIndex ? "highlighted" : ""}`}
        ></span>
      );
    }
    return lines;
  };

  return <div className="dashed-lines-container">{renderDashedLines()}</div>;
};

export default DashedLines;
