import React from "react"
import FloatingBubbles from "./FloatingBubbles"
// import Header from "../Header";
// import Footer from "../footer";

export default function Home(){
    return(
        <>
            <FloatingBubbles />
        </>
    )
}