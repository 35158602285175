import * as React from "react";
import * as ReactDOM from "react-dom";


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./Header";
import Footer from "./footer";
import App from "./App"; 
import Contact from "./Contact"
import Login from "./Login";
import Blog from "./Blog/Blog";
import Post from "./Blog/Post";
import BlogPage from "./Blog/Blogpage";
import AdjacentPosts from "./Blog/AdjacentPosts";
import Store from "./Store/Store";
import Product from "./Store/Product";

const routing = (
    <Router>
        <Routes>
            <Route exact path="/" element={<App />} />
            <Route exact path="/Login" element={<Login />} />

            <Route exact path="/Contact" element={<Contact />} />

            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/:blogid" element={
                <>
                    <Header />
                    <Post />
                    <AdjacentPosts />
                    <Footer />
                </>
            } />
            <Route exact path="/blogpage/*" element={<BlogPage />} />

            {/* <Route exact path="/store" element={<Store />} /> */}
            {/* <Route exact path="/store/:productid" element={
                <>
                    <Header />
                    <Product />
                    <Footer />
                </>
            } /> */}
        </Routes>
    </Router>
)

export default routing;