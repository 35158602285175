import React from "react";
import "./FloatingBubbles.css";

const FloatingBubbles = () => {
  return (
    <div className="container">
      <div className="profile-circle">
        {/* Add your profile picture here */}
        <img
          src="/images/home_images/post_4_eiffel_tower.jpg" // Replace with your image URL
          alt="Profile"
          className="profile-image"
        />
      </div>
      <div className="bubble bubble1">வணக்கம்</div>
      <div className="bubble bubble2">Hello</div>
      <div className="bubble bubble3">नमस्ते</div>
      <div className="bubble bubble4">Bonjour</div>
    </div>
  );
};

export default FloatingBubbles;
