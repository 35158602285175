import React from "react";
import "./Blog.css";
import Header from "../Header";
import Footer from "../footer";
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import AdjacentPosts from "./AdjacentPosts";




const DATA = gql`
    query MyQuery {
        postsConnection {
            edges {
                node {
                    id
                    createdAt
                    slug
                    title
                    excerpt
                    coverImage {
                        url
                    }
                    categories {
                        name
                        slug
                    }
                    content {
                        html
                        json
                    }
                    author {
                        bio
                        name
                        id
                        image {
                            url
                        }
                    }
                    photoCollection {
                      id
                      fileName
                      url
                    }
                    featuredPost
                }
            }
        }
    }
`;

function Design(props){
    return(
            // <NavLink key={props.id} to={"/blog/post/"} className="post_outline">
            <NavLink key={props.id} to={`/blog/${props.id}`} className="post_outline">
                <img className="image" src={props.img} alt="post" />
                <div className="Heading">
                    <p className="title">{props.title}</p>
                </div>
                <p className="content">{props.excerpt}</p>
                {/* <p className="category">{track.node.categories[0].name}</p> */}
                <p className="date-time">{(new Date(props.cdate)).toLocaleDateString()}</p>
                
            </NavLink>
        // </div>
    )
}


export default function Blog(){

    const { loading, error, data } = useQuery(DATA);
    console.log(data);
    
    if(loading) return <div class="loader"></div>;
    
    if(error) return `Error. ${error.message}`;
    
    const all_data = data.postsConnection.edges.map(( track ) => {
        return(
            <Design
                key = { track.node.id }
                id = { track.node.id }
                img = { track.node.coverImage.url }
                title = { track.node.title }
                excerpt = { track.node.excerpt }
                cdate = { track.node.createdAt }
                slug = { track.node.slug}
                pNumber = {track.node.postNumber}
            />
        )
    }).sort().reverse()
        
        
        return(
            <>
            <Header />
            <div className="all_post_area">
                { all_data }
            </div>
            <Footer />
        </>
    );
};